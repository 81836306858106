<script setup lang="ts">
const { contractId } = defineProps<{ contractId: string | number }>()
const { contract } = await useReservationAgreement(
  contractId.toString(),
  'client',
)
</script>

<template>
  <div class="mb-4 flex flex-col gap-2">
    <h2 class="text-4xl">
      {{ $t('account.modal.unsignedContract.client.title') }}
    </h2>
    <i18n-t
      class="pr-8"
      keypath="account.modal.unsignedContract.client.content.text"
      scope="global"
      tag="p"
    >
      <template #houseName>{{ contract?.houseName }}</template>
      <template #bold>
        <strong>
          {{ $t('account.modal.unsignedContract.client.content.bold') }}
        </strong>
      </template>
    </i18n-t>

    <ReservationAgreementClientInformationCard
      v-if="contract"
      :check-in-date="contract.checkInDate"
      :check-out-date="contract.checkOutDate"
      :destination="(contract as any).destination"
      :house-name="contract.houseName"
      :house-photo="contract.houseFirstPhotoUrl"
      size="small"
    />
  </div>
</template>
