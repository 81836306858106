<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    text?: string
    variant?: 'secondary' | 'beige'
  }>(),
  {
    modelValue: false,
    text: undefined,
    variant: 'beige',
  },
)
defineEmits<{
  'update:model-value': [boolean]
  'target-click': []
}>()

const {
  public: { deepLinkOwnerApp },
} = useRuntimeConfig()
const isSecondary = computed(() => props.variant === 'secondary')
const isBeige = computed(() => props.variant === 'beige')
</script>

<template>
  <transition name="fade">
    <div
      v-if="modelValue"
      :class="[
        'flex flex-1 justify-between gap-2 p-4 text-left text-md lg:text-center',
        { 'bg-beige': isBeige },
        { 'bg-primary-100': isSecondary },
      ]"
    >
      <i18n-t class="mb-0 w-full" :keypath="text" tag="p">
        <template #link>
          <BaseButton
            class="ml-1.5 cursor-pointer !underline"
            :href="deepLinkOwnerApp"
            target="_blank"
            variant="link"
            @click="$emit('target-click')"
          >
            {{ $t('global.open') }}
          </BaseButton>
        </template>
      </i18n-t>
      <BaseIcon
        class="mt-0.5 flex-none cursor-pointer lg:mt-0"
        name="cancel"
        @click="$emit('update:model-value', false)"
      />
    </div>
  </transition>
</template>
<style scoped>
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
